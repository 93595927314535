var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "conoce" } },
    [
      _c("header", [
        _c("h1", { staticClass: "main-title text-left" }, [
          _vm._v(_vm._s(_vm.$tc("conoce.title", 1)) + " "),
          _c("br"),
          _vm._v(" " + _vm._s(_vm.$tc("conoce.title", 2)))
        ])
      ]),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "py-5" },
            [
              _c("b-col", [
                _c("h2", { staticClass: "text-pink" }, [
                  _vm._v(" " + _vm._s(_vm.$tc("conoce.subtitle")) + " ")
                ])
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "pb-5" },
            [
              _c(
                "b-col",
                {
                  staticClass: "p-1 pl-lg-5 pr-lg-4",
                  attrs: { cols: "12", lg: "6" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-100 d-flex flex-column justify-content-between"
                    },
                    [
                      _c("div", { staticClass: "daily-card pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card1.title")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-body bgk-blue text-white d-flex pl-3 pl-lg-5 py-3"
                          },
                          [
                            _c("p", { staticClass: "w-75 text-left h6" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$tc("conoce.card1.body")) + " "
                              )
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "daily-card pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card2.title")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-body bgk-blue text-white pl-3 pl-lg-5 py-3 pr-1"
                          },
                          [
                            _c("p", { staticClass: "text-left" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$tc("conoce.card2.body")) + " "
                              )
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "daily-card pb-4 pb-lg-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card3.title")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-body bgk-blue text-white pl-3 pl-lg-5 py-3 pr-1"
                          },
                          [
                            _c("p", { staticClass: "text-left" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$tc("conoce.card3.body")) + " "
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-0 d-flex align-items-center",
                  attrs: { cols: "12", lg: "6" }
                },
                [
                  _c("img", {
                    staticClass: "img-fluid d-lg-none",
                    attrs: {
                      src: require("../assets/desde_adentro/foto1.jpg"),
                      alt: ""
                    }
                  }),
                  _c("div", {
                    staticClass: "w-100 h-100 d-none d-lg-block",
                    style:
                      "background-image: url(" +
                      require("../assets/desde_adentro/foto1.jpg") +
                      ");background-size: cover;"
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "p-0 d-flex align-items-center order-2 order-lg-1",
                  attrs: { cols: "12", lg: "6" }
                },
                [
                  _c("img", {
                    staticClass: "img-fluid d-lg-none",
                    attrs: {
                      src: require("../assets/desde_adentro/foto2.jpg"),
                      alt: ""
                    }
                  }),
                  _c("div", {
                    staticClass: "w-100 h-100 d-none d-lg-block",
                    style:
                      "background-image: url(" +
                      require("../assets/desde_adentro/foto2.jpg") +
                      ");background-size: cover;"
                  })
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-1 pr-lg-5 pl-lg-4 order-1 order-lg-2",
                  attrs: { cols: "12", lg: "6" }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "h-100 d-flex flex-column justify-content-between"
                    },
                    [
                      _c("div", { staticClass: "daily-card pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-pink text-white py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card4.title")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-body bgk-blue text-white d-flex pl-3 pl-lg-5 py-3"
                          },
                          [
                            _c("p", { staticClass: "w-75 text-left h6" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$tc("conoce.card4.body")) + " "
                              )
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "daily-card pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-black text-green py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card5.title")))
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "daily-card pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-green text-blue py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card6.title")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-body bgk-pink text-white d-flex pl-3 pl-lg-5 py-3 pr-1"
                          },
                          [
                            _c("p", { staticClass: "w-75 text-left h6" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$tc("conoce.card6.body")) + " "
                              )
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "daily-card pb-4" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-green text-blue py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card7.title")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-body bgk-pink text-white d-flex pl-3 pl-lg-5 py-3 pr-1"
                          },
                          [
                            _c("p", { staticClass: "text-left h6" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$tc("conoce.card7.body", 1)) +
                                  " "
                              ),
                              _c("br"),
                              _c("small", [
                                _vm._v(_vm._s(_vm.$tc("conoce.card7.body", 2)))
                              ])
                            ])
                          ]
                        )
                      ]),
                      _c("div", { staticClass: "daily-card pb-4 pb-lg-0" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-header bgk-green text-blue py-2 pl-3 pl-lg-5 text-left"
                          },
                          [
                            _c("h5", { staticClass: "p-0 m-0" }, [
                              _vm._v(_vm._s(_vm.$tc("conoce.card8.title")))
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "daily-card-body bgk-pink text-white d-flex pl-3 pl-lg-5 py-3 pr-1"
                          },
                          [
                            _c("p", { staticClass: "text-left h6" }, [
                              _vm._v(
                                " " + _vm._s(_vm.$tc("conoce.card8.body")) + " "
                              )
                            ])
                          ]
                        )
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }